import React, {useEffect} from 'react';
import terms from '../assets/terms-en.pdf';
import Document from 'react-pdf';

function Terms() {
  return (
    <object data={terms} width="100%" style={{height : "100vh"}}>
    </object>
  
  );
}

export default Terms;
