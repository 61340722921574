import logo from '../assets/logo.png';
import i18n from 'i18next';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import horizons from '../assets/coloured_logo.png';
import partner from '../assets/3hpartners_trimmed_new.png';
import Roll from 'react-reveal/Roll';
import { BrowserRouter as Router, Routes, Navigate , Route, Link} from 'react-router-dom';

import '../App.css';

function Home() {


  const handleLanguageClick = (item) => {
    handleLanguageChange(item);
  }

  const handleLanguageChange = (lang) => {
    if(lang === "en") {
      document.querySelector("#it_btn").classList.remove("active");
      document.querySelector("#en_btn").classList.add("active");
    } else {
      document.querySelector("#en_btn").classList.remove("active");
      document.querySelector("#it_btn").classList.add("active");
    }
    i18n.changeLanguage(lang);
  }

  const {t} = useTranslation();
  const titleVal = `${t('home_title')}`;
  const hrefVal =  window.location.href;

  return (
    <>
    <Helmet>
      <title>{titleVal}</title>
      <meta name="description" content={t('meta_data')}></meta>
      <link rel="canonical" href={hrefVal} />
    </Helmet>
    <div className="main-image">
      <div className="container_layout">
        <h1 className="d-flex justify-content-center">
          <div>{t('h1_heading_part_1')}&nbsp;</div> 
          <div>
              <span className="border_h1">{t('h1_heading_part_2')}</span>
          </div>
        </h1>
        <div className="overlay"></div>
        
      </div>
    </div>

    <div className="">
      <div className="">
        <img src={logo} className="logo" />
      </div>
      <div className="col-6">
        <div className="lang_btns">
        <input type="radio" name="lang" id="en_btn" className="a11y caps ax-button white" value="en" defaultChecked onChange = {() => handleLanguageClick("en")} />
        <label for="en_btn" className="caps">en</label>
        <span className="px-2 white">|</span>
        <input type="radio" name="lang" id="it_btn" className="a11y caps ax-button white" value="it" onChange = {() => handleLanguageClick("it")} />
        <label for="it_btn" className="caps">it</label>
  
        </div>
      </div>
    </div>
    <div className="container-fluid">  
      <div className="row orange py-4 align-items-center">
        <div className="col-lg-6">
          <div className="meta_container_title">
            <h2 className="py-4 desc_title">{t('meta_desc_title')}</h2>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 px-lg-5">
          <p className="meta_container_desc py-4 font_1">{t('meta_desc')}</p>
        </div>
      </div>
    </div>
    
    <div className="container-fluid"> 
      <div className="row align-items-center py-4">
        <div className="col-lg-6 border-right py-4">
          <div className="horizon_container">
            <img src={horizons} className="sponsor_icon" />
            <div className="visit_container">
              <a href="https://3horizons.com/" target="_blank" rel="noopener" aria-label="Visit 3horizons">{t("visit")} <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>

        <div className="col-lg-6 py-4">
          <div className="horizon_container">
            <img src={partner} className="sponsor_icon_2" />
            <div className="visit_container_2">
              <a href="https://3hpartners-consulting.com/" target="_blank" rel="noopener" aria-label="Visit 3hpartner">{t("visit")} <i class="fa fa-chevron-right" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer className="footer py-4">
      <div className="footer_container d-flex d-sm-flex justify-content-lg-evenly justify-content-xs-between justify-content-md-between justify-content-sm-between align-items-center">
        <div>{t("copyright")}</div>
        <div className="hide_on_mobile">|</div>
        <div>
          <Link to="/privacy/privacy-en.pdf" target="_blank" rel="noopener">{t("privacy")}</Link>
        </div>
         <div className="hide_on_mobile">|</div>
        <div>
          <Link to="/Terms/terms-en.pdf" target="_blank" rel="noopener">{t("terms")}</Link>
        </div>

      </div>
    </footer>
  </>
  );
}

export default Home;
