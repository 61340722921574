import React, {useEffect, Component} from 'react';

import privacy from '../assets/privacy-en.pdf';


function Privacy() {
  return (
    <object data={privacy} width="100%" style={{height : "100vh"}}>
    </object>
  );
}

export default Privacy;
