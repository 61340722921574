import logo from './assets/logo.png';
import i18n from 'i18next';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import horizons from './assets/horizons.png';
import partner from './assets/3hpartnerslogo_trimmed.png';
import { BrowserRouter as Router, Routes, Navigate , Route, Link} from 'react-router-dom';
import Home from './Home/home';
import Privacy from './Privacy/privacy';
import Terms from './Terms/terms';
import './App.css';

function App() {
 

   const handleLanguageClick = (item) => {
    handleLanguageChange(item);

  }

  const handleLanguageChange = (lang) => {
    if(lang === "en") {
      document.querySelector("#it_btn").classList.remove("active");
      document.querySelector("#en_btn").classList.add("active");
    } else {
      document.querySelector("#en_btn").classList.remove("active");
      document.querySelector("#it_btn").classList.add("active");
    }
    i18n.changeLanguage(lang);
  }

  const {t} = useTranslation();
  const titleVal = `${t('home_title')}`;
  const hrefVal =  window.location.href;

  return (
    <Router> 
        <div>
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/privacy/:id' element={<Privacy />}/>
            <Route path='/terms/:id' element={<Terms />}/>
          </Routes>
        </div>
      </Router>
  );
}

export default App;
